import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

import logoIcon from '../../resources/images/logos/lensesio-logo-icon-b-vsmall.png'

class lensesPolicies extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        <section className="mt-5 py-5 px-2">
          <div className="container-1">
            <div className="row pt-4">
              {/* Lenses Policies */}
              <div className="col-md-12 pb-5">
                <table className="w-100 f-14 mb-5">
                  <tr>
                    <td align="left" className="text-align-right">
                      <b>Lenses.io Ltd</b> is a company registered in England &
                      Wales
                      <br />
                      Company Registered Number 09975716
                      <br />
                      VAT number: GB 231980705
                      <br />
                      Registered office address
                      <br />
                      C/O Corporation Service Company (Uk) Limited, 5 Churchill
                      Place, 10th Floor,
                      <br />
                      London, E14 5HU, United Kingdom
                    </td>
                  </tr>
                </table>
              </div>

              {/* Purpose of document */}
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={logoIcon}
                    alt="Lenses.io logo icon"
                    className="pb-5"
                  />
                  <br />
                  <br />
                  <br />
                  <p className="f-26 fw-600 roboto pb-5">
                    <span className="primary-text">Lenses ®</span>
                    <span> Enterprise</span>
                    <br />
                    <br />
                    Modern Slavery and Human Trafficking Policy
                  </p>
                </div>
                <br />
              </div>
            </div>

            <h2>Introduction</h2>

            <p>
              The policy sets lenses.io commitment to preventing slavery and
              human trafficking in our business activities and the steps we have
              put in place with the aim of ensuring that there is no slavery or
              human trafficking in our own business and supply chains. We all
              have a duty to be alert to risks, however small. Employees are
              expected to report their concerns and management to act upon them.
            </p>

            <p>
              Modern slavery is a term used to encompass slavery, servitude,
              forced and compulsory labour, bonded and child labour and human
              trafficking. Human trafficking is where a person arranges or
              facilitates the travel of another person with a view to that
              person being exploited. Modern slavery is a crime and a violation
              of fundamental human rights.
            </p>

            <h2 className="mt-5">Commitments</h2>

            <p>
              We shall be a company that expects everyone working with us or on
              our behalf to support and uphold the following measures to
              safeguard against modern slavery:
            </p>

            <ul>
              <li>
                We have a zero-tolerance approach to modern slavery in our
                organisation and our supply chains.
              </li>
              <li>
                The prevention, detection and reporting of modern slavery in any
                part of our organisation or supply chain is the responsibility
                of all those working for us or on our behalf. Our Employees must
                not engage in, facilitate or fail to report any activity that
                might lead to, or suggest, a breach of this policy.
              </li>
              <li>
                We are committed to engaging with our stakeholders and suppliers
                to address the risk of modern slavery in our operations and
                supply chain.
              </li>
            </ul>

            <h2 className="mt-5">Policies</h2>

            <p>
              The following policies set down our approach to the identification
              of modern slavery risks and steps to be taken to prevent slavery
              and human trafficking in our operations:
            </p>

            <p>
              <b>Whistleblowing policy</b>- the Company encourages all its
              workers, customers and other business partners to report any
              concerns related to its direct activities or its supply chains.
            </p>

            <p>
              <b>Employee Code of Conduct</b> - the Code of Conduct sets down
              the actions and behaviour expected of employees when representing
              the Company.
            </p>

            <p>
              <b> Corporate Social Responsibility (CSR) Policy </b>- The
              Company’s CSR policy summarises how we manage our environmental
              impacts andhow we work responsibly with suppliers and local
              communities.
            </p>

            <h2 className="mt-5">
              Due Diligence Processes for Slavery and Human Trafficking
            </h2>

            <p>
              The Company undertakes due diligence when considering taking on
              new suppliers, and regularly reviews its existing suppliers. The
              Company’s due diligence process includes building long-standing
              relationships with suppliers and making clear our expectations of
              business partners and evaluating the modern slavery and human
              trafficking risks of each new supplier. Invoke sanctions against
              suppliers that fail to improve their performance in line our
              policy, including the termination of the business relationship.
            </p>

            <p>Consistent with our risk-based approach we may require:</p>
            <ul>
              <li>
                Employment and recruitment agencies and other third parties
                supplying workers to our organisation to confirm their
                compliance with our Code of Conduct
              </li>
              <li>
                Suppliers engaging workers through a third party to obtain that
                third parties’ agreement to adhere to the Code
              </li>
              <li>
                As part of our ongoing risk assessment and due diligence
                processes we will consider whether circumstances warrant us
                carrying out audits of suppliers for their compliance with our
                Code of Conduct.
              </li>
              <li>
                {' '}
                If we find that other individuals or organisations working on
                our behalf have breached this policy we will ensure that we take
                appropriate action. This may range from considering the
                possibility of breaches being remediated and whether that might
                represent the best outcome for those individuals impacted by the
                breach to terminating such relationships
              </li>
            </ul>

            <p className="mt-5">
              This Policy affirms its intention to act ethically in our business
              relationships.
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default lensesPolicies

export const pageQuery = graphql`
  query Legals_Lenses_PolicySEO {
    allContentfulPages(
      filter: {
        pageReference: { pageIdentifier: { eq: "Legals_Lenses_Policy" } }
      }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
